<template>
  <div>
    <c-search-box ref="searchbox" @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-plant
            :disabled="isPlantDisable"
            type="search"
            name="plantCd"
            v-model="searchParam.plantCd"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-text
            :afterIcon="[
              {
                name: 'search',
                click: true,
                callbackName: 'add',
                color: 'teal',
              },
              {
                name: 'close',
                click: true,
                callbackName: 'remove',
                color: 'red',
              },
            ]"
            :editable="editable"
            label="기계/장비 분류"
            :disabled="true"
            :search="true"
            name="facilityTypeName"
            @add="addType"
            @remove="removeType"
            v-model="searchParam.facilityTypeName"
          >
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-process
            :editable="editable"
            :disabled="isProcessDisable"
            :plantCd="searchParam.plantCd"
            label="공종"
            name="processCd"
            v-model="searchParam.processCd"
          >
          </c-process>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-vendor
            :editable="editable"
            :disabled="isVendorDisable"
            :plantCd="searchParam.plantCd"
            :processCd="searchParam.processCd"
            label="업체"
            name="vendorCd"
            v-model="searchParam.vendorCd"
          >
          </c-vendor>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-datepicker
            :range="true"
            label="최근안전검사기간"
            name="period"
            v-model="period"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="기계/장비 목록"
      tableId="equipmentInsp"
      :columns="grid.columns"
      :selection="popupParam.type"
      :data="grid.data"
      gridHeight="500px"
      rowKey="saiContructFacilityId"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <div>
          <q-btn-group>
            <c-btn label="선택" icon="check" @btnClicked="select" />
            <c-btn label="검색" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </div>
      </template>
      <template v-slot:customArea="{ props }">
        <template v-if="props.row['validFlagName']">
          <q-chip
            :color="
              setTagColor(
                props.row.validFlagName === '유효기간 초과'
                  ? 'Y'
                  : props.row.validFlagName === '입력중'
                  ? 'NE'
                  : 'orange'
              )
            "
            :class="
              props.row.validFlagName === '유효기간 초과' ? 'blinking' : null
            "
            text-color="white"
          >
            <q-avatar
              v-if="props.row.validFlagName === '유효기간 초과'"
              icon="alarm"
              color="red"
              text-color="white"
            />
            <q-avatar
              v-if="props.row.validFlagName === '입력중'"
              icon="create"
              color="grey-5"
              text-color="white"
            />
            {{
              setTagName(
                props.row.validFlagName === "유효기간 초과"
                  ? "Y"
                  : props.row.validFlagName
              )
            }}
          </q-chip>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from "@/js/selectConfig";
export default {
  name: "hazard-equipment-class-pop",
  props: {
    popupParam: {
      type: Object,
      default: function () {
        return {
          type: "single",
          plantCd: "",
          processCd: "",
          vendorCd: "",
        };
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function () {
        return "auto";
      },
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: "plantName",
            field: "plantName",
            style: "width:150px",
            label: "공사현장",
            align: "center",
            sortable: true,
          },
          {
            name: "processName",
            field: "processName",
            style: "width:100px",
            label: "공종",
            align: "center",
            sortable: true,
          },
          {
            name: "vendorName",
            field: "vendorName",
            label: "업체",
            style: "width:100px",
            align: "center",
            sortable: true,
          },
          {
            name: "facilityTypeName",
            field: "facilityTypeName",
            style: "width:100px",
            label: "기계/장비유형",
            align: "center",
            sortable: true,
          },
          {
            name: "facilityName",
            field: "deviceNo",
            label: "기계/장비명",
            align: "left",
            style: "width:200px",
            type: "link",
            sortable: true,
          },
          {
            name: "deviceNo",
            field: "deviceNo",
            label: "기기번호",
            align: "center",
            style: "width:100px",
            sortable: true,
          },
          {
            name: "managerNo",
            field: "managerNo",
            label: "관리번호",
            align: "center",
            style: "width:100px",
            sortable: true,
          },
          // {
          //   name: "carryinDate",
          //   field: "carryinDate",
          //   label: "반입일",
          //   style: "width:80px",
          //   align: "center",
          //   sortable: true,
          // },
          // {
          //   name: "useDate",
          //   field: "useDate",
          //   label: "사용기간",
          //   style: "width:150px",
          //   align: "center",
          //   sortable: true,
          // },
          // {
          //   name: "safetyDate",
          //   field: "safetyDate",
          //   style: "width:100px",
          //   label: "최근안전검사일",
          //   align: "center",
          //   sortable: true,
          // },
          // {
          //   name: "validFlagName",
          //   field: "validFlagName",
          //   style: "width:130px",
          //   label: "유효검사잔여일수",
          //   type: "custom",
          //   align: "center",
          //   sortable: true,
          // },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        startYmd: "",
        endYmd: "",
        facilityTypeCd: "",
        facilityTypeName: "",
        vendorCd: "",
        processCd: "",
      },
      isPlantDisable: false,
      isProcessDisable: false,
      isVendorDisable: false,
      colorItems: [
        { colorCode: "Y", colorName: "유효기간 초과", colorClass: "red" },
        { colorCode: "NE", colorName: "입력중", colorClass: "grey-5" },
      ],
      period: [],
      editable: true,
      listUrl: "",
      deleteUrl: "",
      popupOptions: {
        isFull: true,
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      isResult: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  watch: {
  },
  beforeDestroy() {},
  methods: {
    init() {
      // role setting
      // url setting
      this.listUrl = selectConfig.sai.machinery.list.url;

      if (this.popupParam.plantCd) {
        this.isPlantDisable = true;
      } else if (this.popupParam.processCd) {
        this.isProcessDisable = true;
      } else if (this.popupParam.vendorCd) {
        this.isVendorDisable = true;
      }
      // list setting
      this.getList();
    },
    getList() {
      if (this.period) {
        this.searchParam.startYmd = this.period[0];
        this.searchParam.endYmd = this.period[1];
      } else {
        this.searchParam.startYmd = "";
        this.searchParam.endYmd = "";
      }
      // 전체조회
      this.$http.url = this.listUrl;
      this.$http.type = "GET";
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      });
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.popupOptions.title = "기계/장비  상세"; // 기계/장비  상세
      this.popupOptions.param = {
        saiContructFacilityId: row ? row.saiContructFacilityId : "",
      };
      this.popupOptions.target = () =>
        import(`${"./hazardEquipmentClassDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.height = "300px";
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    onItemClick() {
      this.linkClick(null);
    },
    addType() {
      this.popupOptions.title = "기계/장비 유형 검색";
      this.popupOptions.param = {
        type: "single",
      };
      this.popupOptions.target = () =>
        import(`${"@/pages/mdm/pjm/cmd/machineryTypeInsManagePop.vue"}`);
      this.popupOptions.width = "30%";
      this.popupOptions.visible = true;
      this.popupOptions.isFull = false;
      this.popupOptions.closeCallback = this.closeEquipmentPopup;
    },
    closeEquipmentPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data.length > 0) {
        this.searchParam.facilityTypeName = data[0].codeName;
        this.searchParam.facilityTypeCd = data[0].code;
      }
    },
    removeType() {
      this.searchParam.facilityTypeCd = "";
      this.searchParam.facilityTypeName = "";
    },
    setTagColor(data) {
      if (this.colorItems) {
        let color =
          this.$_.find(this.colorItems, { code: data }) ||
          this.$_.find(this.colorItems, { colorCode: data });
        return color ? color.colorClass : "blue";
      }
    },
    setTagName(data) {
      if (this.colorItems) {
        let color =
          this.$_.find(this.colorItems, { code: data }) ||
          this.$_.find(this.colorItems, { colorCode: data });
        return color ? color.colorName : data;
      }
    },
    select() {
      let selectData = this.$refs["table"].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit("ALERT", {
          title: "안내", // 안내
          message: "기계/장비를  지정하세요.", // 기계/장비를 지정하세요.
          type: "warning", // success / info / warning / error
        });
      } else {
        this.$emit("closePopup", selectData);
      }
    },
  },
};
</script>
<style>
.blinking {
  -webkit-animation: blink 3s ease-in-out infinite alternate;
  -moz-animation: blink 3s ease-in-out infinite alternate;
  animation: blink 3s ease-in-out infinite alternate;
}
@-webkit-keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>